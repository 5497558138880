<template>
  <div class="examination-deatils">
    <canvas id="myCanvas" v-show="false" width="343" height="200"></canvas>
    <div class="content" v-show="!dialogVisible">
      <div class="video-views" :class="{ adjustment: adjustment }">
        <div class="video-sroll" :class="{ 'video-border': adjustment }">
          <div
            style="
              font-weight: bold;
              font-size: 16px;
              line-height: 50px;
              text-align: center;
            "
            v-if="adjustment"
          >
            为了保证你您的考试顺利，请认真完成下列各项设备调试
          </div>
          <div class="video-box" :class="{ 'videx-box-width': !adjustment }">
            <div class="video-view">
              <video
                style="width: 90%"
                id="localVideo"
                autoplay="autoplay"
                muted="true"
              ></video>
            </div>
            <div class="videoSelectTypes">
              <div>摄像头：</div>
              <el-select
                v-model="filteredValue"
                placeholder="请选择"
                size="mini"
                @change="selectGetUserMediaPlay"
              >
                <el-option
                  v-for="(item, index) in filtered"
                  :key="index"
                  :label="item && item.label ? item.label : `默认${index + 1}`"
                  :value="index"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="viedo-tips" v-if="adjustment">
            <p>未检测到/出现异常请查看下方步骤！</p>
            <p>
              1.推荐使用Chrome谷歌浏览器、Firefox火狐浏览器；如果使用360浏览器等国产浏览器，请使用极速模式。
            </p>
            <p>2.请关闭你电脑的杀毒软件，并刷新页面</p>
            <p>3.是否在地址栏左侧允许了摄像头/麦克风的授权？</p>
            <img
              style="width: 100%"
              src="../../../assets/imgs/tips_01.png"
              alt=""
            />
            <img
              style="width: 100%"
              src="../../../assets/imgs/tips_02.png"
              alt=""
            />
          </div>
          <div style="clear: both; text-align: center" v-if="adjustment">
            <div class="gradient-background btn" @click="isVerifyFace(2)">
              确认
            </div>
          </div>
        </div>
      </div>
      <div style="line-height: 100px; font-size: 20px; text-align: center">
        <div
          class="gradient-border btn"
          @click="goback"
          style="font-size: 14px; float: left"
        >
          返回
        </div>
        <span v-if="!adjustment">
          {{ itemData && itemData.title }}
        </span>
      </div>
      <el-card class="box-card" v-if="examination && !adjustment">
        <div class="card">
          <div>
            【{{
              examination.subject.type == "SC"
                ? "单选题"
                : examination.subject.type == "MC"
                ? "多选题"
                : examination.subject.type == "TF"
                ? "判断题"
                : "简答题"
            }}】
          </div>
          <p>{{ examination.index + 1 }}. {{ examination.subject.title }}</p>
          <el-radio-group
            v-model="examination.value"
            v-if="examination.subject.type == 'SC'"
          >
            <el-radio
              v-for="(opt, _opt) in examination.subject.options"
              :key="_opt"
              :label="opt.index"
              >{{ opt.title }}</el-radio
            >
          </el-radio-group>
          <el-checkbox-group
            v-model="examination.value"
            v-if="examination.subject.type == 'MC'"
          >
            <el-checkbox
              v-for="(opt, _opt) in examination.subject.options"
              :key="_opt"
              :label="opt.index"
              >{{ opt.title }}</el-checkbox
            >
          </el-checkbox-group>
          <el-radio-group
            v-model="examination.value"
            v-if="examination.subject.type == 'TF'"
          >
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
          <el-input
            v-if="examination.subject.type == 'QA'"
            type="textarea"
            :rows="10"
            placeholder="请在该区域内作答"
            v-model="examination.value"
          >
          </el-input>
        </div>
        <div class="gradient-background next" @click="submit">
          {{ examination.total != examination.index + 1 ? "下一题" : "提交" }}
        </div>
      </el-card>
      <el-card class="tips-card" v-if="!adjustment">
        <p>剩余时间</p>
        <p class="timing">{{ timer }}</p>
        <el-divider></el-divider>
        <p>当前进度</p>
        <p>
          {{ examination && examination.index + 1 }}/{{
            examination && examination.total
          }}
        </p>
      </el-card>
    </div>
    <el-dialog
      v-model="dialogVisible"
      :modal="status > 2"
      :show-close="false"
      :top="status == 1 ? '30vh' : '15vh'"
    >
      <div class="loading" v-if="status == 1">
        {{ tips }}
        <div v-if="erroring">
          <el-button @click="closeWindow">关闭此窗口</el-button>
        </div>
      </div>
      <div class="tips" v-if="status == 2">
        <div class="title">
          {{ itemData.title }}
        </div>
        <div class="time">考试时间长：{{ itemData.times }}分钟</div>
        <div
          class="description"
          v-if="itemData.description && itemData.description != 'null'"
        >
          （{{ itemData.description }}）
        </div>
        <div class="rule">
          <p>考试规则</p>
          <p style="color: red; font-size: 16px">
            考试答题期间不可返回上一题作答，请知悉。
          </p>
          <p>
            1）考生须使用配备摄像头的台式电脑或笔记本电脑进行考试，不支持使用手机、IPAD等移动设备作答。
          </p>
          <p>
            2）请考生使用最新版Chrome浏览器、360极速浏览器或火狐浏览器登录考试答题系统（在线考试平台兼容Windows和Mac
            OS系统）。
          </p>
          <p>
            3）考生须在密闭、独立的空间中完成作答，空间内不得有其他人或声音进行干扰。考试过程中严禁与其他人员交谈。
          </p>
          <p>
            4）考生在考试过程中须确保电脑以及摄像头与考试相关的硬件设备都处于正常工作状态。考试全程须确保摄像头设备录制到本人上半身，考生需确保考试环境的采光良好。
          </p>
          <p>
            5）考试过程中禁止考生对考试内容进行拍照、截屏、录屏或抄写。如有考生将试题泄露或传播，考试主办方将依法追究该考生责任并通报至该考生所属单位严肃处理。
          </p>
          <p>6）其他应当视为违反考试规则的行为，以考试主办方认定为准。</p>
        </div>
        <div class="confirms">
          <div
            class="gradient-background btn"
            @click="loginForm ? confirm() : isVerifyFace(1)"
          >
            {{ loginForm ? "下一步" : "确认" }}
          </div>
        </div>
      </div>
      <div class="submit-tips" v-if="status == 3">
        <p>是否现在交卷？</p>
        <p>交卷后无法再对答案进行修改</p>
        <div class="gradient-border btn" @click="dialogVisible = false">
          取消
        </div>
        <div class="gradient-background btn" @click="confirmSubmit">确认</div>
      </div>
      <div class="submit-loading" v-if="status == 4">
        <p>交卷中</p>
        <el-progress
          :text-inside="true"
          :stroke-width="26"
          :percentage="70"
        ></el-progress>
        <p style="font-size: 12px">正在提交答案，请不要关闭窗口</p>
      </div>
    </el-dialog>
    <DrawerTracking
      @onBack="hideDrawerTracking"
      @success="successTracking"
      :drawer="isTracking"
      :mediaConstraints="mediaConstraints"
    />
  </div>
</template>
<script>
import Peer from "peerjs";
import { domainName } from "../../../assets/js/storage";
import DrawerTracking from "../../../components/DrawerTracking";
let _timer;
export default {
  name: "examinationDeatils",
  data() {
    return {
      isTracking: false, // 是否打开人脸核身
      trackingType: 1, // 人脸核身类型
      isAdoptTracking: false, // 是否通过人脸核身
      erroring: false,
      itemData: "",
      status: 1,
      dialogVisible: false,
      timer: "00:00",
      totalTime: "3600",
      currentTime: "00:59:59",
      examination: null,
      adjustment: false,
      loginForm: null,
      isDone: false,
      peer1: null,
      peers: null,
      isInit: false,
      tips: "正在进入考试......",
      // socket 类型
      type: {
        sendMessageEx: "send::message::one",
        sendGroup: "send::message::group",
        offer: "offer",
        answer: "answer",
        candidate: "candidate",
        cmd: "cmd",
        heart: "heart",
      },
      websocket: null,
      hasSubmit: false,
      mediaConstraints: {
        video: true,
        audio: true,
      },
      configuration: {
        iceServers: [
          {
            urls: "stun:meeting.dreamount.cn:3478",
          },
          {
            urls: "turn:meeting.dreamount.cn:3478",
            username: "kurento",
            credential: "kurento",
          },
        ],
      },
      screenConstraints: {
        video: {
          cursor: "always" | "motion" | "never",
          displaySurface: "application" | "browser" | "monitor" | "window",
        },
      },
      offerOptions: {
        iceRestart: true,
        offerToReceiveAudio: false,
        offerToReceiveVideo: true,
      },
      localMediaStream: null,
      localScreenStream: null,
      rtcPeerConnection: null,
      cmdUser: null,
      setTimes: 20000,
      uploadTimer: null,
      filteredValue: 0, // 保存当前电脑可用摄像头列表
      filtered: [], // 保存当前电脑可用摄像头列表
      isAudioOK: false, //音频是否正常
    };
  },
  methods: {
    // 隐藏人脸核身
    hideDrawerTracking() {
      this.isTracking = false;
    },
    // 人脸核身功能
    successTracking() {
      this.isAdoptTracking = true;
      this.hideDrawerTracking();
      if (this.trackingType == 1) {
        this.confirm();
      } else {
        this.adjustmentConfirm();
      }
    },
    isVerifyFace(type = 1) {
      //1 没有监考， 2 有监考
      this.trackingType = type;
      if (this.$route.query.verifyFace == "true" && !this.isAdoptTracking) {
        this.isTracking = true;
        return false;
      }
      if (type == 1) {
        this.confirm();
      } else {
        this.adjustmentConfirm();
      }
    },
    cutPicture() {
      if (!this.localMediaStream) return;
      let v = document.querySelector("video");
      let canvas = document.getElementById("myCanvas");
      let ctx = canvas.getContext("2d");
      ctx.drawImage(v, 0, 0, 343, 200);
      let oGrayImg = canvas.toDataURL("image/jpeg");
      let imgName = new Date().valueOf() + ".jpg";
      var file = dataURLtoFile(oGrayImg, imgName);

      // 上传视频截图
      let formData = new FormData();
      formData.append("file", file);
      this.$https
        .postWithFile(
          "/api/my/exam/upload/" + this.$route.query.examinationId,
          formData
        )
        .then((res) => {
          if (res.data.warning) {
            // this.$message({
            //   type: "warning",
            //   duration: 30000,
            //   message: res.data.message,
            // });
            this.$alert(res.data.message, "警告", {
              confirmButtonText: "知道了",
            });
          }
        });
      //将base64转换为文件
      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      }
    },
    closeWindow() {
      window.onbeforeunload = null;
      window.close();
    },
    adjustmentConfirm() {
      this.initPeer();
      if (this.localMediaStream) {
        this.adjustment = false;
        this.startExame();
        this.uploadTimer = setInterval(this.cutPicture, this.setTimes);
      } else {
        this.$message.warning("请调整好摄像头之后再进入考试");
      }
    },
    goback() {
      window.onbeforeunload = null;
      this.$confirm("是否确定要退出考试？", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$storage.setStorage([
            {
              key: "examination",
              value: 0,
            },
          ]);
          if (this.localMediaStream) {
            this.cancalCloseVideo();
          }
          window.close();
        })
        .catch((action) => {
          window.onbeforeunload = function () {
            return "您确定本页的操作完成了吗？页面将关闭或刷新。";
          };
        });
    },
    confirm() {
      this.dialogVisible = false;
      if (this.loginForm && this.status == 2) {
        this.adjustment = true;
      }
      if (this.loginForm) {
        this.openLocalMedia();
      } else {
        this.startExame();
      }
    },
    setTimer() {
      const _this = this;
      let _timer = setInterval(function () {
        if (_this.totalTime) {
          _this.totalTime--;
          var minute = parseInt(_this.totalTime / 60);
          var second = parseInt(_this.totalTime % 60);
          _this.timer = minute + ":" + second;
        } else {
          clearInterval(_timer);
          window.clearInterval(_this.uploadTimer);
          _this.uploadTimer = null;
        }
      }, 1000);
    },
    confirmSubmit() {
      this.status = 4;
    },
    submit() {
      if (this.examination.value == null) {
        this.$message.error("请先作答！");
        return;
      }
      this.$https
        .post("/api/my/exam/submitsubject/" + this.examination.id, {
          answer: this.examination.value,
          subjectIndex: this.examination.index,
        })
        .then((res) => {
          if (res.success) {
            const data = res.data;
            this.isDone = data && data.isDone;
            if (!data.isDone) {
              this.examination = {
                ...data,
                value: data.subject.type == "MC" ? [] : null,
              };
            } else {
              /*是否回显*/
              if (data.needDisplay) {
                var content = "";
                if (data.displayResult) {
                  /**判断是否极格 */
                  let tipsTxt = "很遗憾，您没有通过此次考试";
                  let tipsTxtCalss = "echoErrorTitle";
                  if (data.pass) {
                    tipsTxt = "恭喜您通过此次考试";
                    tipsTxtCalss = "echoPassTitle";
                  }
                  const displayContentText = data.displayContent;
                  const src = data.displayImage;
                  content =
                    '<div class="echoPass"><div class="' +
                    tipsTxtCalss +
                    '">' +
                    tipsTxt +
                    '</div><div class="echoPassContent">' +
                    displayContentText +
                    '</div><img style="max-width:100%;" src=' +
                    (this.$domainName + src) +
                    " /></div>";
                } else {
                  const displayContentText = data.displayContent;
                  const src = data.displayImage;
                  content =
                    '</div><div class="echoPassContent">' +
                    displayContentText +
                    '</div><img style="max-width:100%;" src=' +
                    (this.$domainName + src) +
                    " /></div>";
                }
                this.$confirm(content, "", {
                  confirmButtonText: "确定",
                  showCancelButton: false,
                  showClose: false,
                  dangerouslyUseHTMLString: true,
                  center: true,
                })
                  .then(() => {
                    this.cancalCloseVideo();
                    this.closeWindow();
                  })
                  .catch(() => {
                    this.cancalCloseVideo();
                    this.closeWindow();
                  });
              } else {
                this.$alert("", "考试完成！", {
                  confirmButtonText: "确定",
                  center: true,
                  callback: () => {
                    this.cancalCloseVideo();
                    this.closeWindow();
                  },
                });
              }
            }
          }
        })
        .catch((error) => {
          if (error.code == "business_error") {
            this.$message.error(error.debug);
            window.clearInterval(this.uploadTimer);
            this.uploadTimer = null;
          } else {
            this.$message.error(error.debug);
          }
        });
    },
    // 关闭摄像头
    cancalCloseVideo() {
      if (this.localMediaStream) {
        this.localMediaStream.getTracks()[0].stop();
      }
    },
    // 连接服务
    connSocket() {
      this.initPeer();
    },

    // 打开本地音视频,用promise这样在打开视频成功后，再进行下一步操作
    openLocalMedia() {
      // 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          var getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.getUserMedia;
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            //不存在则报错
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      // 先请求权限
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(() => {
          //枚举设备
          var devices = navigator.mediaDevices
            .enumerateDevices()
            .then((devicesList) => {
              var filtered = devicesList.filter(
                (device) => device.kind === "videoinput"
              );
              console.log(devicesList);
              console.log(filtered);
              //显示设备名称
              // 保存数据
              if (filtered.length > 0) {
                this.filtered = filtered;
                navigator.mediaDevices
                  .getUserMedia({ audio: true })
                  .then(() => {
                    //设置设备
                    this.isAudioOK = true;
                    this.mediaConstraints = {
                      audio: this.isAudioOK,
                      video: { exact: filtered[0].deviceId },
                    };
                    this.getUserMediaPlay();
                  })
                  .catch(() => {
                    this.isAudioOK = false;
                    this.mediaConstraints = {
                      video: { exact: filtered[0].deviceId },
                    };
                    this.getUserMediaPlay();
                    console.log("音频异常");
                  });
              }
            });
        })
        .catch((err) => {
          console.log("eer:" + err.message);
          this.$message.warning("打开本地音视频设备失败" + err.message);
        });
    },
    // 选择调用打开本地音视频设备
    selectGetUserMediaPlay(filteredIndex) {
      console.log(filteredIndex);
      let filteredItem = this.filtered[filteredIndex];
      this.filteredValue = filteredIndex;
      this.mediaConstraints = {
        audio: this.isAudioOK,
        video: { deviceId: { exact: filteredItem.deviceId } },
      };
      this.getUserMediaPlay(true);
    },
    // 调用打开本地音视频设备
    getUserMediaPlay(isSelect) {
      var errBack = function (e) {
        console.log("An error has occurred!", e);
        this.$message.warning("打开本地音视频设备失败" + err.message);
      };
      let localVideo = document.getElementById("localVideo");
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        console.log("navigator.mediaDevices.getUserMedia");
        console.log(this.mediaConstraints);
        navigator.mediaDevices
          .getUserMedia(this.mediaConstraints)
          .then((stream) => {
            this.localMediaStream = stream;
            try {
              localVideo.srcObject = stream;
            } catch (error) {
              localVideo.src = URL.createObjectURL(stream);
            }
            localVideo.onloadedmetadata = function (e) {
              localVideo.play();
            };
            if (isSelect) {
              this.$message({
                message: "切换设备成功",
                type: "success",
              });
            }
            this.watchStream(1000);
          })
          .catch((err) => {
            console.log(err);
            cthis.$message.warning("打开本地音视频设备失败" + err.message);
          });
      } else if (navigator.getUserMedia) {
        console.log("navigator.getUserMedia");
        navigator.getUserMedia(
          this.mediaConstraints,
          function (stream) {
            this.localMediaStream = stream;
            localVideo.src = stream;
            localVideo.play();
            if (isSelect) {
              this.$message({
                message: "切换设备成功",
                type: "success",
              });
            }
            this.watchStream(1000);
          },
          errBack
        );
      } else if (navigator.webkitGetUserMedia) {
        // WebKit-prefixed
        console.log("navigator.webkitGetUserMedia");
        navigator.webkitGetUserMedia(
          this.mediaConstraints,
          function (stream) {
            this.localMediaStream = window.webkitURL.createObjectURL(stream);
            localVideo.src = window.webkitURL.createObjectURL(stream);
            localVideo.play();
            if (isSelect) {
              this.$message({
                message: "切换设备成功",
                type: "success",
              });
            }
            this.watchStream(1000);
          },
          errBack
        );
      } else if (navigator.mozGetUserMedia) {
        // Mozilla-prefixed
        console.log("navigator.mozGetUserMedia");
        navigator.mozGetUserMedia(
          this.mediaConstraints,
          function (stream) {
            this.localMediaStream = window.URL.createObjectURL(stream);
            localVideo.src = window.URL.createObjectURL(stream);
            localVideo.play();
            if (isSelect) {
              this.$message({
                message: "切换设备成功",
                type: "success",
              });
            }
            this.watchStream(1000);
          },
          errBack
        );
      }
    },
    initPeer() {
      console.log("initPeer" + this.loginForm.userId);
      this.peers = new Peer(this.loginForm.userId, {
        host: "peer.dreamount.cn",
        path: "/myapp",
        secure: true,
        config: {
          iceServers: [
            { url: "stun:meeting.dreamount.cn" },
            {
              url: "turn:meeting.dreamount.cn",
              username: "kurento",
              credential: "kurento",
            },
          ],
        },
      });
      this.peers.on("open", (id) => {
        console.log("open");
      });
      this.peers.on("connection", (conn) => {
        console.log("incomming connection");
        conn.on("data", (data) => {
          // Will print 'hi!'
          console.log(data);
          if (data.type != null) {
            if (data.type == "V") {
              this.peers.call(data.id, this.localMediaStream);
              console.log("call");
            } else if (data.type == "M") {
              this.$message.warning(data.msg);
              console.log(data);
            }
          }
        });
        conn.on("open", () => {});
      });
    },
    startExame() {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const query = this.$route.query || null;
      if (!query) {
        this.$router.push({
          path: "/examination",
        });
      }
      this.$https
        .post("/api/my/exam/start/" + query.examinationId)
        .then((res) => {
          if (res.success) {
            const data = res.data;
            if (!data.subject) {
              this.tips = "考试数据异常！";
              return;
            }
            this.examination = {
              ...data,
              value: data.subject.type == "MC" ? [] : null,
            };
            this.totalTime = data.leftTime;
            this.setTimer();
          } else {
            this.dialogVisible = true;
            this.status = 1;
            this.erroring = true;
            this.tips = "错误：" + error.debug;
            return;
          }
        })
        .catch((error) => {
          this.dialogVisible = true;
          this.status = 1;
          this.erroring = true;
          this.tips = "错误：" + error.debug;
          return;
        });
    },
    watchStream(timer) {
      const _this = this;
      if (_timer) {
        clearInterval(_timer);
      }
      _timer = setInterval(function () {
        const lives = _this.localMediaStream.getTracks()[0];
        if (lives.readyState == "ended" && !this.isDone) {
          clearInterval(_timer);
          _this
            .$confirm("摄像头异常，考试可能无效！", "提示", {
              confirmButtonText: "确定",
              showCancelButton: false,
              showConfirmButton: false,
              type: "warning",
              center: true,
            })
            .then(() => {
              // _this.$router.go(-1);
              // _this.connSocket();
            });
        }
      }, timer);
    },
  },

  mounted() {
    this.$nextTick(() => {
      // setInterval(this.checkHeart, 30000);
    });
    localStorage.setItem("examination", "1");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const query = this.$route.query || null;
    if (!query) {
      this.$router.push({
        path: "/examination",
      });
    }
    this.status = 2;
    this.itemData = query;
    this.dialogVisible = true;
    if (query.needCam && query.needCam != "false") {
      this.loginForm = {
        userId: userInfo.id,
        roomId: query.examinationId,
      };
    } else {
      this.loginForm = null;
    }
    const that = this;
    window.onbeforeunload = function () {
      return "您确定本页的操作完成了吗？页面将关闭或刷新。";
    };
    window.onunload = function () {
      that.$storage.setStorage([
        {
          key: "examination",
          value: 0,
        },
      ]);
    };
  },
  components: {
    DrawerTracking,
  },
};
</script>

<style lang="less" scope>
.examination-deatils {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #edf0f5;
  .video-views {
    height: 250px;
    position: absolute;
    top: 50px;
    width: 100%;
    overflow: hidden;
    .video-sroll {
      padding: 0 20px;
      background-color: #fff;
      border-radius: 5px;
    }
    .video-border {
      border: 1px solid #ddd;
    }
    .video-box {
      width: 49%;
      margin-right: 1%;
      float: left;
      text-align: center;
      background-color: #928f9a;
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
    }
    .video-view {
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .videoSelectTypes {
      position: absolute;
      top: 100%;
      left: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 7px;
    }
    .videoSelectType {
      margin: 3px 5px;
      padding: 5px 8px;
      // white-space: normal;
      background-color: #fff;
      color: #000;
      cursor: pointer;
      border-radius: 2px;
    }
    .videx-box-width {
      width: 300px;
      display: block;
      background: transparent;
    }
    .viedo-tips {
      width: 50%;
      float: left;
      padding: 10px;
      text-align: left;
      line-height: 30px;
      background-color: #eef0f4;
      height: 400px;
      overflow: auto;
    }
  }
  .adjustment {
    top: 20%;
    padding: 0 20%;
    height: auto;
  }
  .content {
    position: absolute;
    height: 100%;
    width: 100%;
    .tips-card {
      position: absolute;
      right: 50px;
      top: 50px;
      background-color: #fff;
      text-align: center;
      line-height: 2;
      padding: 20px 40px;
      color: #5e6482;
      overflow: initial;
      font-size: 14px;
      .el-divider--horizontal {
        margin: 10px 0;
      }
      .timing {
        color: tomato;
      }
      .submit {
        position: absolute;
        bottom: -60px;
        width: 100%;
        left: 0;
      }
    }
    .box-card {
      position: relative;
      color: #5e6482;
      width: 60%;
      margin: 10% 20%;
      .el-card__header {
        font-size: 16px;
        font-weight: bold;
      }
      .card {
        font-size: 14px;
        padding: 20px;
        line-height: 30px;
        .el-radio,
        .el-checkbox {
          width: 100%;
          line-height: 30px;
          padding-left: 10px;
          font-size: 14px;
          margin-right: 0;
          text-overflow: ellipsis;
          white-space: normal;
          line-height: 18px;
          vertical-align: middle;
          display: inline-block;
        }
      }
      .next {
        padding: 0 20px;
        float: right;
        margin: 0 20px 20px 0;
        height: 30px;
        line-height: 30px;
      }
    }
  }
}

.loading {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
}
.tips {
  padding: 10px 20px;
  line-height: 2;
  font-size: 13px;
  .title {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
  }
  .time {
    text-align: center;
  }
  .description {
    text-align: center;
    margin-bottom: 10px;
  }
  .rule {
    border: 1px solid #e4e7ed;
    padding: 10px;
    border-radius: 5px;
    height: 210px;
    overflow: auto;
  }
  .confirms {
    padding-top: 20px;
    text-align: center;
  }
}

.btn {
  display: inline-block;
  padding: 0 20px;
  margin: 10px;
  height: 30px;
  line-height: 30px;
}

.submit-tips {
  text-align: center;
  line-height: 3;
  font-size: 14px;
}
.submit-loading {
  text-align: center;
  line-height: 3;
  font-size: 14px;
}

.el-dialog__header {
  display: none;
}
</style>
